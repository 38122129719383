import React, { useState, useEffect } from 'react';
import { Typography, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import api from '../api'; // Axios 인스턴스 가져오기
import '../style.css'; // CSS 파일 임포트
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ReservationForm from './reservationForm';


const localizer = momentLocalizer(moment);


const Calendargoogle = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [reservationDialogOpen, setReservationDialogOpen] = useState(false);
    const [cartData, setCartData] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);


    const handleAuth = async () => {
    try {
      const response = await api.post('/au/auth');
      const data = await response.data;
      if (data.url) {
        window.location.href = data.url;  // 인증 URL로 리디렉션
      } else {
        console.error('No URL found in response');
      }
    } catch (error) {
      console.error('Error fetching auth URL:', error);
    }
  };


  const gotoCarendal= async () => {
    try {
      const response = await api.post('/au/getCalendarList');
      const data = await response.data;
      if (data.url) {
        window.location.href = data.url;  // 인증 URL로 리디렉션
      } else {
        console.error('No URL found in response');
      }
      setReservationDialogOpen(true);
    } catch (error) {
      console.error('Error fetching auth URL:', error);
    }

  };

  const handleCallback = async () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      try {
        //const response = await axios.get(`http://localhost:3000/oauth2callback?code=${code}`);
         const response = await api.post('/au/oauth2callback',code);
        const data = await response.data;
      if (data.url) {
        window.location.href = data.url;  // 인증 URL로 리디렉션
      } else {
        console.error('No URL found in response');
      }
      } catch (error) {
        console.error('Error retrieving tokens:', error);
      }
    }
  };


   const fetchCartData = async () => {
    try {
      const response = await api.post('/ca/selectallAcart', { Adivi: 0 });
      setCartData(response.data);
    } catch (error) {
      console.error('Error fetching cart data:', error);
    }
  };



   React.useEffect(() => {
    handleCallback();
    fetchCartData();
  }, []);


  const filterCartDataByDiviNum = (diviNum) => {
    return cartData.filter(cart => cart.diviNum === diviNum).sort((a, b) => a.sunWe - b.sunWe);
  };



   const fetchEvents = async (start, end) => {
    try {
      const response = await api.post('/au/events',{
        calendarId: 'c_477jf3vmd9bbin5upv925ed93g@group.calendar.google.com',  // 여기에 원하는 캘린더 ID를 입력
        start: start.toISOString(),
        end: end.toISOString()
      });
      const googleEvents = response.data;
      const formattedEvents = googleEvents.map(event => ({
        id: event.id, // 여기에서 이벤트 ID를 포함합니다.
        title: event.summary,
        start: new Date(event.start.dateTime || event.start.date),
        end: new Date(event.end.dateTime || event.end.date)
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching events', error);
    }
  };

  useEffect(() => {
    // 초기 날짜 범위 설정 (예: 현재 월)
    const start = moment().startOf('month').toDate();
    const end =  moment().add(2, 'months').endOf('month').toDate();
    fetchEvents(start, end);
  }, []);



     const handleRangeChange = (range) => {
    const start = range.start;
    const end = range.end;
    fetchEvents(start, end);
    };

    const fetchData = async (filters = {}) => {
        setLoading(true);
        try {
          const response = await api.post('/au/getCalendarList', filters);
          const rowsWithId = response.data.map((row, index) => ({ id: row.gNum || index, ...row }));
          setData(rowsWithId);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };


      useEffect(() => {
        fetchData();
      }, []);


   const handleReservationFormClose = () => {
    setReservationDialogOpen(false);
    setSelectedEvent(null);
  };


   const handleEventSelect = async (event) => {
     try {
    // 백엔드에 이벤트 세부 정보를 요청합니다.
    //console.log(event);
//console.log(event); // 이벤트 객체의 구조 확인
    // 현지 시간대로 변환
    const start = new Date(event.start).toLocaleString('sv-SE', { timeZone: 'Asia/Seoul' });
    const end = new Date(event.end).toLocaleString('sv-SE', { timeZone: 'Asia/Seoul' });
    const title = event.title.includes(':') ? event.title.split(':')[1].trim() : event.title;
    const googleEventId = event.id || event.googleEventId; // 이벤트 객체에서 ID 가져오기


  // console.log('Google Event ID:', googleEventId); // ID 확인
    
    const response = await api.post('/re/getEventDetails', {
      googleEventId: googleEventId,
     
    });
    
    const eventData = response.data;
    // 받아온 이벤트 세부 정보를 ReservationForm으로 넘깁니다.
    setReservationDialogOpen(true);
     setSelectedEvent({ ...eventData, googleEventId }); // 이벤트 ID 포함
  } catch (error) {
    console.error('Error fetching event details:', error);
  }

  };

 const generateOrderId = () => {
  const now = new Date();
  
  // 날짜를 기반으로 앞 6자리 생성 (YYMMDD)
  const datePart = now.getFullYear().toString().slice(2) + 
                   (now.getMonth() + 1).toString().padStart(2, '0') + 
                   now.getDate().toString().padStart(2, '0');
  
  // 나머지 4자리를 랜덤 숫자로 채움
  const randomPart = Math.floor(1000 + Math.random() * 9000);

  // 두 부분을 합쳐서 10자리 주문 코드 생성
  return `${datePart}${randomPart}`;
};



  const handleSave = async (updatedData) => {
    try {
      const date = moment(updatedData.reserDate); // updatedData.reserDate를 moment 객체로 변환
     const startOfLastMonth = date.subtract(1, 'month').startOf('month').toDate(); // 이전 달의 시작일
    const endOfNextMonth = date.add(3, 'month').endOf('month').toDate(); // 다음 달의 종료일

  

      if (updatedData.gNum) {
        // 기존 데이터 수정
        const response = await api.post('/re/updateReser', updatedData);
        if (response.status === 200) {
          fetchEvents(startOfLastMonth, endOfNextMonth);
          setReservationDialogOpen(false);
        }
       } else {
        // 신규 데이터 입력
        const orderId = generateOrderId(); // 고유한 주문 ID 생성 함수
        updatedData.orderId = orderId;
        const response = await api.post('/re/insertReser', updatedData);
        if (response.status === 200) {
          fetchEvents(startOfLastMonth, endOfNextMonth);
          setReservationDialogOpen(false);
        }
      }
    } catch (error) {
      console.error('Error saving reservation:', error);
    }
  };

  const handleDelete = async (updatedData) => {

    if (updatedData.moneyCheck =='Y' || updatedData.moneyCheck =='W' ){
      window.alert('해당 예약은 이미 결제대기중이거나 결제완료된 예약입니다')
      return;
    }
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }


    try {
        const date = moment(updatedData.reserDate); // updatedData.reserDate를 moment 객체로 변환
     const startOfLastMonth = date.subtract(1, 'month').startOf('month').toDate(); // 이전 달의 시작일
    const endOfNextMonth = date.add(3, 'month').endOf('month').toDate(); // 다음 달의 종료일

    
      const response = await api.post('/re/deleteReser', updatedData);
      fetchEvents(startOfLastMonth, endOfNextMonth);
      setReservationDialogOpen(false);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };
      

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '16px' }}>
        <Typography variant="h4" style={{ flexGrow: 1 }}>
          일정관리
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={gotoCarendal}
          style={{ marginLeft: '16px' }}
        >
          일정예약
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAuth}
          style={{ marginLeft: '16px' }}
        >
          구글연동
        </Button>
      </div>
        <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView="week" // 주 단위 보기로 설정
        style={{ height: 700 }}
         onRangeChange={handleRangeChange}  // 달력 범위 변경 시 이벤트 가져오기
         onSelectEvent={handleEventSelect}
      />
        <ReservationForm
         open={reservationDialogOpen}
        handleClose={handleReservationFormClose}
        events={events} // 구글 캘린더 이벤트 전달
        filterCartDataByDiviNum={filterCartDataByDiviNum}
        handleSave={handleSave}
        handleDelete={handleDelete}
        data={selectedEvent}
      />

    </div>
  );
};

export default Calendargoogle;

